@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: 'Poppins', sans-serif;
}
.active{
  color: #565350;
}
.nav-style{
  background-color: transparent;
}
.nav-items{
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 510px){
  .navbar-light .navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: transparent !important;
  }
}

.box{
  width: 654px;
  height: 136px;
  background: #FDFDFD;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.no-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.large-box{
  width: 1242px;
  height: 190px;
  background: #FDFDFD;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.button-grup{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.tag{
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.02em;

  color: #565350;
}
.gs{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.012em;
  color: #FFFFFF;

  width: 221.65px;
  height: 70.31px;
  border: none;
  background: #565350;
}

.lm{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.012em;
  color: #BDBCBC;

  background: #FFFFFF;
  border: 3px solid #BDBCBC;
  width: 221.65px;
  height: 70.31px;
}

.lm-2{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.012em;
  color: #BDBCBC;

  border: none;
}
.lm-2:hover{
  font-style: none;
  color: #BDBCBC;
  text-decoration: none;
}

.p{
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.012em;

  color: #656766;
  margin: 0;
}

/* RESPONSIVE */
@media only screen and (max-width: 1439px) {
  .large-box{
    width: 1000px;
    height: 190px;
    display: flex;
  }

  .button-grup{
    margin-top: 3%;
  }
}

@media only screen and (max-width: 1023px) {
  .box{
    width: 565px;
  }
  .large-box{
    width: 700px;
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .button-grup{
    margin-top: 3%;
  }
  .tag{
    font-size: 30px;
  }
  .gs{
    width: 175px;
  }
  .lm{
    width: 175px;
  }
}
@media only screen and (max-width: 767px){
  .box{
    width: 100%;
    height: auto;
    background-color: transparent;
    box-shadow: none;
    justify-content: center;
  }
  .lm{
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.22); 
  }
  .lm-2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 175px;
    height: 70.31px;
    background: #FFFFFF;
    border: 3px solid #BDBCBC;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.22); 
  }
  .gs{
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.22); 
  }
  .large-box{
    width: 500px;
  }
}

@media only screen and (max-width: 510px){
  .large-box{
    width: 350px;
    height: 155px;
  }

  .large-box .tag{
    font-size: 22px;
  }

  .large-box .gs{
    width: 125.83px;
  height: 39.91px;
  }
  .large-box .lm{
    width: 125.83px;
    height: 39.91px;
  }

  .lm{
    width: 125.83px;
    height: 39.91px;
  }
  .gs{
    width: 125.83px;
    height: 39.91px;
  }
  .lm-2{
    width: 125.83px;
    height: 39.91px;
  }
}
.gallery img {
  transition: all 0.3s ease-in-out;
}
.gallery img:hover {
  transform: scale(1.05);
}

.mb{
  display: none !important;
}

.drop-shadow{
    box-shadow: 15px 15px 50px grey;
}

/* RESPONSIVE */
@media only screen and (min-width: 1441px) {
  .gallery img{
    display: block;
  }
}
@media only screen and (max-width: 767px){
  .wb{
    display: none !important;
  }
  .mb{
    display: flex !important;
  }
}
.f-end{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.page-1{
  background-image: url(/static/media/page-1.ccfd20d3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.page-2{
  overflow: hidden;
}
.page-3{
  background-image: linear-gradient(to right, #FFFF , rgba(0, 0, 0, 0.15));
}
.page-5{
  margin-bottom: 10%;
}
.wrapper{
  background-color: #565350;
}
.footer{
  height: 516px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.f-mb{
  display: none;
}
.title{
  color: #565350;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: 0.02em;
}

.tagline-desc{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */

  letter-spacing: 0.02em;

  color: #6F7376;
}

.footer-link{
  display: flex;
  flex-direction: column;
}

.footer-link p{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */

  letter-spacing: 0.02em;

  color: #A2A5A8;
}

.footer-link a{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */
  
  letter-spacing: 0.02em;
  
  color: #FFFFFF;
}
.copyright{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  /* or 214% */

  text-align: center;
  letter-spacing: 0.02em;

  color: #A2A5A8;
  align-self: flex-end;
}
.merge{
  bottom: -40%;
}
.bk2{
  margin-top: 10% ;
}
/* IMAGES */
.bot-right{
    bottom: -15%;
    right: -10%;
}
#FootLeaf{
  left: -4%;
  top: -45%;
}
.flower{
  display: flex;
  justify-content: flex-end;
}
.c-leaf{
  left: -18%;
  top: 10%;
  z-index: 0;
}
.c-leaf-m{
  display: none;
}
.img-mb{
  display: none;
}
.pencil-mb{
  display: none;
}

/* RESPONSIVE */
@media only screen and (min-width: 1760px) {
  .tagline {
    margin-top: 10%;
  }

  .c-leaf{
    left: -10%;
  }
}

@media only screen and (max-width: 1439px) {
  .tagline {
    margin-top: 15%;
  }

  .bot-right{
    right: -15%;
  }
  .c-leaf{
    left: -30%;
  }
  .bk2{
    margin-top: 5% ;
    margin-bottom: 5% ;
  }
  #FootLeaf{
    width: 18%;
  }
  .page-5{
    margin-bottom: 15%;
  }
  .merge{
    bottom: -50%;
  }
  .fbl{
    width: 20%;
  }
}

@media only screen and (min-width: 1800px){
  .merge{
    bottom: -60%;
  }
}
@media only screen and (max-width: 1023px) {
  .title{
    font-size: 30px;
  }
  .tagline-desc{
    font-size: 14px;
  }
  .merge{
    bottom: -40%;
  }
  #FootLeaf{
    width: 15%;
    top: -20%;
  }
  .top-left{
    width: 15%;
  }
  .flower img{
    width: 15%;
  }
  .bot-right{
    width: 50%;
    right: -15%;
  }
  .footer{
    height: 380px;
  }
}

@media only screen and (max-width: 767px){
  .page-1{
    background-position: left;
  }
  .title{
    text-align: center;
  }
  .tagline-desc{
    text-align: center;
  }
  .c-leaf-m{
    display: block;
    bottom: 4%;
    left: -50%;
    width: 100%;
  }
  .footer{
    padding-top: 30%;
    height: auto;
  }
  .bk2{
    display: flex;
    justify-content: center;
  }
  .t-left{
    text-align: left
  }  
  .t-right{
    text-align: right
  }
  .f-mb{
    display: block;
  }.f-wb{
    display: none;
  }
  /* IMAGES */
  .img-mb{
    display: flex;
    justify-content: center;
  }
  .img-wb{
    display: none;
  }
  .c-leaf{
    left: -45%;
    top: 25%;
    width: 100%;
  }
}

@media only screen and (max-width: 510px){
  .merge {
    bottom: -30%;
  }
  .pencil-mb{
    display: block;
    width: 50%;
    right: 0;
    top: 0;
  }
  .title{
    font-size: 28px;
  }
  .c-leaf{
    left: -55%;
    top: 40%;
    width: 100%;
  }
}

@media only screen and (max-width: 375px){
  .merge {
    bottom: -27%;
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

